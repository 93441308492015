<template>
  <div>
    <!-- non finished new design -->
    <div v-if="false">
      <div class="py-10 px-8">
        <div class="mx-auto max-w-610">
          <h1 class="mb-6 cursor-pointer text-32 font-bold" @click="evergreenTab = 1">Evergreen</h1>
          <div v-if="evergreenTab == 0">
            <div class="mb-8 flex items-center gap-6">
              <button
                @click="activeTab = 0"
                class="inline-flex items-center rounded-3xl text-base font-medium"
                style="outline: none"
                :class="[activeTab == 0 ? 'bg-black px-4 py-2 text-white' : '']"
              >
                All
              </button>
              <button
                @click="activeTab = 1"
                class="inline-flex items-center rounded-3xl text-base font-medium"
                style="outline: none"
                :class="[activeTab == 1 ? 'bg-black px-4 py-2 text-white' : '']"
              >
                Energy
              </button>
              <button
                @click="activeTab = 2"
                class="inline-flex items-center rounded-3xl text-base font-medium"
                style="outline: none"
                :class="[activeTab == 2 ? 'bg-black px-4 py-2 text-white' : '']"
              >
                Climate change
              </button>
              <button
                @click="activeTab = 3"
                class="inline-flex items-center rounded-3xl text-base font-medium"
                style="outline: none"
                :class="[activeTab == 3 ? 'bg-black px-4 py-2 text-white' : '']"
              >
                Exercise
              </button>
              <button
                @click="activeTab = 4"
                class="inline-flex items-center rounded-3xl text-base font-medium"
                style="outline: none"
                :class="[activeTab == 4 ? 'bg-black px-4 py-2 text-white' : '']"
              >
                Buddhism
              </button>
              <button
                @click="activeTab = 5"
                class="inline-flex items-center rounded-3xl text-base font-medium"
                style="outline: none"
                :class="[activeTab == 5 ? 'bg-black px-4 py-2 text-white' : '']"
              >
                Productivity
              </button>
            </div>
            <div v-if="activeTab == 0" class="grid gap-8">
              <div
                class="border rounded-2xl border-gray-90 bg-white p-6 dark-mode-border-0 dark-mode-bg"
              >
                <div class="grid gap-6">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center gap-2">
                      <div class="h-8 w-8">
                        <img
                          src="/images/user.png"
                          alt="Richard"
                          class="h-full w-full rounded-full object-cover"
                        />
                      </div>
                      <div class="flex flex-1 items-center justify-between gap-2">
                        <span class="truncate w-12 text-lg font-bold lg:w-auto"
                          >Richard Shaw</span
                        >
                        <span
                          class="truncate w-12 text-base text-gray-80 lg:w-auto dark-mode:text-dark-mode-10"
                          >@shawrichardo</span
                        >
                      </div>
                    </div>
                    <div class="relative">
                      <div class="cursor-pointer">
                        <horizontal-dots class="w-5 dark-mode:hidden" />
                        <dark-horizontal-dots class="hidden w-5 dark-mode:block" />
                      </div>
                    </div>
                  </div>
                  <p class="text-lg leading-6 dark-mode:text-white">
                    Aliens thought about coming to Earth but then saw our ratings.
                  </p>
                  <p class="ext-lg leading-6 dark-mode:text-white">We only have 1 star.</p>
                </div>
              </div>
              <div
                class="border rounded-2xl border-gray-90 bg-white p-6 dark-mode:border-0 dark-mode:bg-dark-mode-70"
              >
                <div class="grid gap-6">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center gap-2">
                      <div class="h-8 w-8">
                        <img
                          src="/images/user.png"
                          alt="Richard"
                          class="h-full w-full rounded-full object-cover"
                        />
                      </div>
                      <div class="flex flex-1 items-center justify-between gap-2">
                        <span class="truncate w-12 text-lg font-bold lg:w-auto dark-mode:text-white"
                          >Richard Shaw</span
                        >
                        <span
                          class="truncate w-12 text-base text-gray-80 lg:w-auto dark-mode:text-dark-mode-10"
                          >@shawrichardo</span
                        >
                      </div>
                    </div>
                    <div class="relative">
                      <div class="cursor-pointer">
                        <horizontal-dots class="w-5 dark-mode:hidden" />
                        <dark-horizontal-dots class="hidden w-5 dark-mode:block" />
                      </div>
                    </div>
                  </div>
                  <p class="text-lg leading-6 dark-mode:text-white">
                    Aliens thought about coming to Earth but then saw our ratings.
                  </p>
                  <p class="ext-lg leading-6 dark-mode:text-white">We only have 1 star.</p>
                </div>
              </div>
              <div
                class="border rounded-2xl border-gray-90 bg-white p-6 dark-mode:border-0 dark-mode:bg-dark-mode-70"
              >
                <div class="grid gap-6">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center gap-2">
                      <div class="h-8 w-8">
                        <img
                          src="/images/user.png"
                          alt="Richard"
                          class="h-full w-full rounded-full object-cover"
                        />
                      </div>
                      <div class="flex flex-1 items-center justify-between gap-2">
                        <span class="truncate w-12 text-lg font-bold lg:w-auto dark-mode:text-white"
                          >Richard Shaw</span
                        >
                        <span
                          class="truncate w-12 text-base text-gray-80 lg:w-auto dark-mode:text-dark-mode-10"
                          >@shawrichardo</span
                        >
                      </div>
                    </div>
                    <div class="relative">
                      <div class="cursor-pointer">
                        <horizontal-dots class="w-5 dark-mode:hidden" />
                        <dark-horizontal-dots class="hidden w-5 dark-mode:block" />
                      </div>
                    </div>
                  </div>
                  <p class="text-lg leading-6 dark-mode:text-white">
                    Aliens thought about coming to Earth but then saw our ratings.
                  </p>
                  <p class="ext-lg leading-6 dark-mode:text-white">We only have 1 star.</p>
                </div>
              </div>
            </div>
            <div v-if="activeTab == 1" class="grid gap-8">
              <div
                class="border rounded-2xl border-gray-90 bg-white p-6 dark-mode:border-0 dark-mode:bg-dark-mode-70"
              >
                <div class="grid gap-6">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center gap-2">
                      <div class="h-8 w-8">
                        <img
                          src="/images/user.png"
                          alt="Richard"
                          class="h-full w-full rounded-full object-cover"
                        />
                      </div>
                      <div class="flex flex-1 items-center justify-between gap-2">
                        <span class="truncate w-12 text-lg font-bold lg:w-auto dark-mode:text-white"
                          >Richard Shaw</span
                        >
                        <span
                          class="truncate w-12 text-base text-gray-80 lg:w-auto dark-mode:text-dark-mode-10"
                          >@shawrichardo</span
                        >
                      </div>
                    </div>
                    <div class="relative">
                      <div class="cursor-pointer">
                        <horizontal-dots class="w-5 dark-mode:hidden" />
                        <dark-horizontal-dots class="hidden w-5 dark-mode:block" />
                      </div>
                    </div>
                  </div>
                  <p class="text-lg leading-6 dark-mode:text-white">
                    Aliens thought about coming to Earth but then saw our ratings.
                  </p>
                  <p class="ext-lg leading-6 dark-mode:text-white">We only have 1 star.</p>
                </div>
              </div>
              <div
                class="border rounded-2xl border-gray-90 bg-white p-6 dark-mode:border-0 dark-mode:bg-dark-mode-70"
              >
                <div class="grid gap-6">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center gap-2">
                      <div class="h-8 w-8">
                        <img
                          src="/images/user.png"
                          alt="Richard"
                          class="h-full w-full rounded-full object-cover"
                        />
                      </div>
                      <div class="flex flex-1 items-center justify-between gap-2">
                        <span class="truncate w-12 text-lg font-bold lg:w-auto dark-mode:text-white"
                          >Richard Shaw</span
                        >
                        <span
                          class="truncate w-12 text-base text-gray-80 lg:w-auto dark-mode:text-dark-mode-10"
                          >@shawrichardo</span
                        >
                      </div>
                    </div>
                    <div class="relative">
                      <div class="cursor-pointer">
                        <horizontal-dots class="w-5 dark-mode:hidden" />
                        <dark-horizontal-dots class="hidden w-5 dark-mode:block" />
                      </div>
                    </div>
                  </div>
                  <p class="text-lg leading-6 dark-mode:text-white">
                    Aliens thought about coming to Earth but then saw our ratings.
                  </p>
                  <p class="ext-lg leading-6 dark-mode:text-white">We only have 1 star.</p>
                </div>
              </div>
              <div
                class="border rounded-2xl border-gray-90 bg-white p-6 dark-mode:border-0 dark-mode:bg-dark-mode-70"
              >
                <div class="grid gap-6">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center gap-2">
                      <div class="h-8 w-8">
                        <img
                          src="/images/user.png"
                          alt="Richard"
                          class="h-full w-full rounded-full object-cover"
                        />
                      </div>
                      <div class="flex flex-1 items-center justify-between gap-2">
                        <span class="truncate w-12 text-lg font-bold lg:w-auto dark-mode:text-white"
                          >Richard Shaw</span
                        >
                        <span
                          class="truncate w-12 text-base text-gray-80 lg:w-auto dark-mode:text-dark-mode-10"
                          >@shawrichardo</span
                        >
                      </div>
                    </div>
                    <div class="relative">
                      <div class="cursor-pointer">
                        <horizontal-dots class="w-5 dark-mode:hidden" />
                        <dark-horizontal-dots class="hidden w-5 dark-mode:block" />
                      </div>
                    </div>
                  </div>
                  <p class="text-lg leading-6 dark-mode:text-white">
                    Aliens thought about coming to Earth but then saw our ratings.
                  </p>
                  <p class="ext-lg leading-6 dark-mode:text-white">We only have 1 star.</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="evergreenTab == 1">
            <p class="mb-8 text-lg text-gray-80">
              Evergreen is a collection of your favorite posts randomly selected for your Evergreen
              slots.
            </p>
            <div
              class="border rounded-2xl border-gray-90 bg-white p-6 dark-mode:border-0 dark-mode:bg-dark-mode-70"
            >
              <div class="grid gap-6">
                <h3 class="text-2xl font-bold leading-none">Create a category</h3>
                <input type="text" placeholder="@examplename" class="form-field" />
                <div>
                  <button
                    @click="evergreenTab = 2"
                    class="button small-button bg-main-color-100 text-white"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="evergreenTab == 2">
            <div class="mb-8 flex items-center gap-6">
              <button
                @click="everActiveTab = 0"
                class="inline-flex items-center rounded-3xl text-base font-medium"
                style="outline: none"
                :class="[everActiveTab == 0 ? 'bg-black px-4 py-2 text-white' : '']"
              >
                All
              </button>
              <button
                @click="everActiveTab = 1"
                class="inline-flex items-center rounded-3xl text-base font-medium"
                style="outline: none"
                :class="[everActiveTab == 1 ? 'bg-black px-4 py-2 text-white' : '']"
              >
                Life Hacks
              </button>
            </div>
            <div
              class="border rounded-2xl border-gray-90 bg-white p-6 dark-mode:border-0 dark-mode:bg-dark-mode-70"
            >
              <div class="grid gap-6">
                <div>
                  <h3 class="mb-2 text-2xl font-bold leading-none">
                    Select your first Evergreen post
                  </h3>
                  <p class="text-lg text-gray-80">
                    These are your most popular posts on Twitter so they’d be great to recycle.
                  </p>
                </div>
                <div
                  class="cursor-pointer rounded-2xl bg-white p-6 dark-mode:border-0 dark-mode:bg-dark-mode-70"
                  :class="[
                    isSelected
                      ? 'border-2 border-main-color-100 bg-main-color-50'
                      : 'border border-gray-90',
                  ]"
                >
                  <div class="grid gap-6">
                    <div class="flex items-center justify-between">
                      <div class="flex items-center gap-2">
                        <div class="h-8 w-8">
                          <img
                            src="/images/user.png"
                            alt="Richard"
                            class="h-full w-full rounded-full object-cover"
                          />
                        </div>
                        <div class="flex flex-1 items-center justify-between gap-2">
                          <span
                            class="truncate w-12 text-lg font-bold lg:w-auto dark-mode:text-white"
                            >Richard Shaw</span
                          >
                          <span
                            class="truncate w-12 text-base text-gray-80 lg:w-auto dark-mode:text-dark-mode-10"
                            >@shawrichardo</span
                          >
                        </div>
                      </div>
                    </div>
                    <p class="text-lg leading-6 dark-mode:text-white">
                      Where do rainbows go when they’re bad?
                    </p>
                    <p class="ext-lg leading-6 dark-mode:text-white">
                      Prism. It’s a light sentence
                    </p>
                    <div class="flex items-center gap-1 text-gray-100">
                      <span>Thursday</span>
                      <span>7:14pm</span>
                    </div>
                  </div>
                </div>
                <div
                  class="border cursor-pointer rounded-2xl border-gray-90 bg-white p-6 dark-mode:border-0 dark-mode:bg-dark-mode-70"
                >
                  <div class="grid gap-6">
                    <div class="flex items-center justify-between">
                      <div class="flex items-center gap-2">
                        <div class="h-8 w-8">
                          <img
                            src="/images/user.png"
                            alt="Richard"
                            class="h-full w-full rounded-full object-cover"
                          />
                        </div>
                        <div class="flex flex-1 items-center justify-between gap-2">
                          <span
                            class="truncate w-12 text-lg font-bold lg:w-auto dark-mode:text-white"
                            >Richard Shaw</span
                          >
                          <span
                            class="truncate w-12 text-base text-gray-80 lg:w-auto dark-mode:text-dark-mode-10"
                            >@shawrichardo</span
                          >
                        </div>
                      </div>
                    </div>
                    <p class="text-lg leading-6 dark-mode:text-white">
                      Where do rainbows go when they’re bad?
                    </p>
                    <p class="ext-lg leading-6 dark-mode:text-white">
                      Prism. It’s a light sentence
                    </p>
                    <div class="flex items-center gap-1 text-gray-100">
                      <span>Thursday</span>
                      <span>7:14pm</span>
                    </div>
                  </div>
                </div>
                <div
                  class="border cursor-pointer rounded-2xl border-gray-90 bg-white p-6 dark-mode:border-0 dark-mode:bg-dark-mode-70"
                >
                  <div class="grid gap-6">
                    <div class="flex items-center justify-between">
                      <div class="flex items-center gap-2">
                        <div class="h-8 w-8">
                          <img
                            src="/images/user.png"
                            alt="Richard"
                            class="h-full w-full rounded-full object-cover"
                          />
                        </div>
                        <div class="flex flex-1 items-center justify-between gap-2">
                          <span
                            class="truncate w-12 text-lg font-bold lg:w-auto dark-mode:text-white"
                            >Richard Shaw</span
                          >
                          <span
                            class="truncate w-12 text-base text-gray-80 lg:w-auto dark-mode:text-dark-mode-10"
                            >@shawrichardo</span
                          >
                        </div>
                      </div>
                    </div>
                    <p class="text-lg leading-6 dark-mode:text-white">
                      Where do rainbows go when they’re bad?
                    </p>
                    <p class="ext-lg leading-6 dark-mode:text-white">
                      Prism. It’s a light sentence
                    </p>
                    <div class="flex items-center gap-1 text-gray-100">
                      <span>Thursday</span>
                      <span>7:14pm</span>
                    </div>
                  </div>
                </div>
                <div>
                  <button class="button small-button bg-main-color-100 text-white">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        <div>
          <h1 class="mb-6">Evergreen Posts</h1>
          <div v-if="threads">
            <div
              v-show="
                (threads.length !== 0 || searchInput) &&
                isUserPlanBasicOrAbove(userProfile.customerStatus)
              "
              class="flex flex-row space-x-3"
            >
              <history-search-field
                @input="updateSearchInput"
                @filterChange="updateFilter"
                :isFavorite="true"
              />
              <history-filters
                v-if="!searchInput || !searchInput.keyword"
                @filterChange="updateFilter"
                :isEvergreenQueue="true"
                :canFilterDeletedPosts="false"
              />
            </div>
            <div
              v-show="threads.length !== 0 || timeRange"
              class="space-y-4 md:space-y-0 md:flex md:justify-between mb-6"
            >
              <history-date-time-filter
                v-if="['all', 'hypefuryTweets'].includes(filter)"
                @updateTimeRange="updateTimeRange"
                :isEvergreenQueue="true"
              />
            </div>
          </div>
          <div class="space-y-6">
            <div class="card tweet" v-if="!isTrialPeriodOver && !isOnboardingEvergreenMessageDismissed">
              <div class="card-action flex justify-end">
                <base-button @click="dismissBestTweetsText" class="btn icon small outline"
                  ><inline-svg src="/img/icons/close.svg"
                /></base-button>
              </div>
              <div class="p-5 pt-0">
                <div class="iframe video-container">
                  <iframe
                    height="100%"
                    width="100%"
                    src="https://www.youtube.com/embed/r8fsBroaxM8"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
            <div
              class="space-y-6"
              v-if="firstBatchOfPostsLoaded && threads.length === 0 && !searchInput && !timeRange"
            >
              <ul class="list-disc text-base ml-4 pb-2">
                <li>This is the curated list of your best posts.</li>
                <li>
                  When the
                  <router-link to="/settings#evergreen">Empty slots act as Evergreen slots</router-link>
                  setting is enabled, Hypefury will retweet a random evergreen post whenever a slot of
                  your schedule is empty.
                </li>
                <li>This way, your timeline will stay active even when you are busy or away.</li>
                <li>
                  You can visit your
                  <router-link data-cy="evergreen-add-some-posts" to="/history">History</router-link> page
                  to add some.
                </li>
              </ul>
              <new-button
                data-cy="evergreen-set-best-tweets"
                type="success"
                @click="openSetBestTweetsAsEvergreenModal"
              >
                Pick some evergreen tweets
              </new-button>
            </div>
            <base-alert
              type="warning"
              radius="rounded-2xl"
              class="my-4"
              v-if="userProfile.customerStatus === 'none'"
              data-cy="evergreen-posts-subscription-alert"
            >
              <p
                >You are currently on the free plan. Evergreen posts is a paid feature.<br />
                <router-link data-cy="evergreen-posts-upgrade-link" to="/billing" class="text-main-color-100">Grab a Hypefury subscription</router-link> to unlock evergreen
                posts, unlimited scheduling, autoplugs and much more.</p
              >
            </base-alert>
            <div
              v-if="threads.length !== 0"
              class="dark-mode-bg dark-mode-border-0 bg-white border border-gray-90 rounded-2xl p-6 flex flex-col gap-1 text-dark-mode-15"
              :set="(available = evergreenPosts.length - evergreenPostsRetweetedLastWeek)"
            >
              <p class="text-base">
                Evergreen posts are retweeted at most once a week, in order not to spam your timeline with
                the same retweets.
                <br />
                <br />
              </p>
              <p class="text-base">
                You have a total of
                {{ evergreenPosts.length }}
                evergreen posts and
                {{ evergreenPostsRetweetedLastWeek }}
                of them were retweeted during the last week.
                At the moment, there are
                <span v-if="available >= 0">{{ available }}</span>
                <span v-else>0</span>
                evergreen posts available to be retweeted.
              </p>
            </div>
            <div v-if="firstBatchOfPostsLoaded && threads.length === 0 && (searchInput || timeRange)" class="dark-mode:bg-dark-mode-70 bg-white border border-gray-90 dark-mode:border-0 rounded-2xl p-6">
              <ul class="list-disc ml-4 pb-2">
                <li>No results found.</li>
              </ul>
            </div>
            <div
              data-cy="evergreen-card"
              v-bind:key="thread.id"
              :ref="thread.id"
              v-for="thread in threads"
            >
              <div class=" bg-white border border-gray-90 shadow-xs rounded-2xl dark-mode-bg dark-mode-border-0" v-if="thread.tweets !== undefined">
                <div class="px-6 py-2 border-b dark-mode-border-60 border-gray-90 flex items-center justify-between">
                  <div
                    class="truncate text-base"
                    :set="(postingTime = thread.postingTimeData(userProfile.timezone))"
                  >
                    <span v-if="thread.time.isAfter(new Date())">
                      This {{ postingTime.threadOrTweet }} will be posted {{ postingTime.day }} at
                      {{ postingTime.time }}.
                    </span>
                    <span v-else>
                      Posted
                      <a
                        target="_blank"
                        :href="`https://twitter.com/${userProfile.username}/status/${thread.tweets[0].tweetId}`"
                        class="text-main-color-100"
                      >
                        {{ postingTime.day }} at {{ postingTime.time }}.
                      </a>
                    </span>
                  </div>
                  <div class="flex space-x-2">
                    <tooltip
                      :content="
                        !thread.isFavorite ? 'Set as an Evergreen Post' : 'Unset as an Evergreen Post'
                      "
                    >
                      <base-button
                        data-cy="evergreen-card-evergreen"
                        @click="addToEvergreenQueue(thread)"
                        class="flex items-center justify-center cursor-pointer w-10 h-10 rounded-full outline-none focus:outline-none"
                        :class="thread.isFavorite ? 'text-green-500' : ''"
                        :tooltip="!thread.isFavorite ? 'Set as Evergreen' : 'Unset as Evergreen'"
                      >
                        <inline-svg :src="thread.isFavorite ? '/img/icons/star.svg' : '/img/icons/star-empty.svg'" />
                      </base-button>
                    </tooltip>
                    <base-dropdown
                      menu-fixed
                      data-cy="evergreen-card-menu"
                      class="nav-item"
                      tag="div"
                      title-tag="button"
                      title-classes="nav-link pr-0"
                      v-if="thread.scheduled"
                    >
                      <base-button
                        class="focus:text-main-color-100 flex items-center justify-center cursor-pointer w-10 h-10 rounded-full outline-none focus:outline-none"
                        @click.prevent=""
                        slot="title-container"
                      >
                        <inline-svg src="/img/icons/moreOptions3dot.svg" />
                      </base-button>
                      <base-button
                        data-cy="evergreen-card-retweet-now"
                        @click="retweetNow(thread)"
                        class="new-dropdown-item dark-mode-dropdown-item"
                        v-if="!thread.hasErrors()"
                      >
                        <inline-svg src="/img/icons/new-retweet.svg" />
                        <span>Retweet Now</span>
                      </base-button>
                      <base-button
                        @click="requeue(thread)"
                        class="new-dropdown-item dark-mode-dropdown-item"
                        v-if="!thread.hasErrors()"
                      >
                        <inline-svg src="/img/icons/calendar.svg" />
                        <span>Re-queue</span>
                      </base-button>
                      <base-button
                        data-cy="evergreen-card-schedule-once"
                        @click="scheduleRetweetOnce(thread)"
                        class="new-dropdown-item dark-mode-dropdown-item"
                        v-if="!thread.hasErrors()"
                      >
                        <inline-svg src="/img/icons/new-retweet.svg" />
                        <inline-svg
                          v-if="userProfile.customerStatus === 'none'"
                          src="/img/icons/premium.svg"
                        />
                        <span>Schedule retweet once</span>
                      </base-button>
                      <base-button
                        data-cy="evergreen-card-schedule-repeat"
                        @click="scheduleMultipleRetweets(thread)"
                        class="new-dropdown-item dark-mode-dropdown-item"
                        v-if="!thread.hasErrors()"
                      >
                        <inline-svg src="/img/icons/new-retweet.svg" />
                        <inline-svg
                          v-if="userProfile.customerStatus === 'none'"
                          src="/img/icons/premium.svg"
                        />
                        <span>Schedule multiple retweets</span>
                      </base-button>
                      <hr v-if="!thread.hasErrors()" />
                      <base-button
                        data-cy="evergreen-card-delete-post"
                        @click="deleteThread(thread)"
                        class="new-dropdown-item dark-mode-dropdown-item delete"
                        :disabled="userProfile.customerStatus === 'none'"
                      >
                        <inline-svg stroke="#f56565" class="text-red-500" src="/img/icons/delete.svg" />
                        <span class="text-red-500">Delete Post</span>
                      </base-button>
                    </base-dropdown>
                  </div>
                </div>
                <div class="px-6 py-4 border-0 border-gray-90 dark-mode-border-60" v-if="thread.tweets.length > 1">
                  <time-line type="one-side">
                    <time-line-item
                      v-bind:key="tweet.id"
                      v-for="(tweet, index) in thread.tweets"
                      badge-type="secondary"
                      additionalClasses="row"
                      :title="String(tweet.count + 1)"
                    >
                      <div :class="tweet.media.length > 0 ? 'col-sm-9' : 'col-12'">
                        <p
                          class="card-text whitespace-pre-wrap break-words text-md"
                          v-html="formatTweet(tweet.status)"
                          dir="auto"
                        />
                      </div>
                      <div
                        v-if="thread.hasThreadFinisherTweet && tweet.count === thread.tweets.length - 1"
                        class="tweet-container dark-mode-bg-80 p-4 rounded-md mt-6 px-4 space-y-2"
                      >
                        <div class="flex space-x-2 items-center">
                          <img :src="userProfile.photoURL" class="rounded-full h-4 w-4" />
                          <span class="text-dark-mode-15">@{{ userProfile.username }}</span>
                        </div>
                        <div
                          class="text-base lg:text-lg whitespace-pre-wrap break-words"
                          v-html="formatTweet(thread.tweets[0].status)"
                          dir="auto"
                        />
                      </div>
                      <queue-thumbnail :media="getMedia(thread, index)" />
                      <poll-options :tweet="tweet" />
                    </time-line-item>
                  </time-line>
                </div>
                <div class="px-6 py-4 border-0 dark-mode-border-60 border-gray-90" v-if="thread.tweets.length === 1">
                  <div class="row">
                    <div :class="thread.tweets[0].media.length > 0 ? 'col-sm-9' : 'col-12'">
                      <p
                        class="whitespace-pre-wrap break-word text-md"
                        v-html="formatTweet(thread.tweets[0].status)"
                        dir="auto"
                      />
                    </div>
                    <queue-thumbnail :media="getMedia(thread, 0)" />
                    <poll-options :tweet="thread.tweets[0]" />
                  </div>
                </div>
                <div v-if="thread.tweetMetricsUpdatedAt" class="py-3 px-6 border-t dark-mode-border-60 border-gray-90">
                  <tweets-metrics-card-footer :thread="thread" />
                </div>
                <div
                  class="py-3 px-6 space-x-4 border-t dark-mode-border-60 border-gray-90"
                  v-if="thread.lastAutoRTTime"
                  :set="(lastAutoRTTimeData = thread.lastAutoRTTimeDifference(userProfile.timezone))"
                >
                  <div class="space-x-1 flex items-center text-sm">
                    <inline-svg src="/img/icons/retweet.svg" class="w-5" />
                    <span>Last retweeted {{ lastAutoRTTimeData }}</span>
                  </div>
                  <tooltip content="Growth Program" v-if="thread.source == 'growth-program'">
                    <div class="space-x-1">
                      <inline-svg src="/img/icons/growth.svg" />
                    </div>
                  </tooltip>
                </div>
              </div>
            </div>
          </div>
          <schedule-retweet-modal
            :post="retweetToSchedule"
            @close="closeScheduleRetweetModal"
            :show="isScheduleRetweetModalVisible"
          />
          <div v-if="isScheduleRetweetRecurrentlyModalVisible">
            <schedule-retweet-recurrently-modal
              :post="retweetToSchedule"
              @close="closeScheduleRetweetModal"
              :show="isScheduleRetweetRecurrentlyModalVisible"
            />
          </div>
          <set-best-tweets-as-evergreen-modal
            :show="isSetBestTweetsAsEvergreenModalVisible"
            @close="closeSetBestTweetsAsEvergreenModal"
          />
          <back-to-top-custom />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import dao from '@/dao';
  import lodash from 'lodash';
  import moment from 'moment';
  import { mapGetters, mapState } from 'vuex';
  import { TimeLine, TimeLineItem } from '@/components';
  import { v1 as uuidv1 } from 'uuid';
  import HistoryFilters from '@/components/HistoryFilters';
  import HistorySearchField from '@/components/HistorySearchField';
  import HistoryDateTimeFilter from '@/components/HistoryDateTimeFilter';
  import PollOptions from '../components/PollOptions';
  import QueueThumbnail from './QueueThumbnail';
  import DeletePostMixin from '@/views/Mixins/DeletePostMixin';
  import HistoryMixin from '@/views/Mixins/HistoryMixin';
  import SetBestTweetsAsEvergreenMixin from '@/views/Mixins/SetBestTweetsAsEvergreenMixin.vue';
  import TrialPeriodMixin from '@/views/Mixins/TrialPeriodMixin.vue';
  import BackToTopCustom from '@/views/Widgets/BackToTopCustom';
  import { scrollToElement } from '../util/scrollToElement';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import ScheduleRetweetModal from '@/views/Modals/ScheduleRetweetModal';
  import SetBestTweetsAsEvergreenModal from '@/views/Modals/SetBestTweetsAsEvergreenModal';
  import ScheduleRetweetRecurrentlyModal from '@/views/Modals/ScheduleRetweetRecurrentlyModal';
  import TweetsMetricsCardFooter from '@/components/Cards/TweetsMetricsCardFooter';
  import InspirationTweetsMixin from './Mixins/InspirationTweetsMixin.vue';
  import { Thread } from '../models/Thread';
  import { store } from '@/store';
  import horizontalDots from './../svgs/horizontalDots';
  import darkHorizontalDots from './../svgs/darkHorizontalDots';
  import CustomerStatusMixin from './Mixins/CustomerStatusMixin.vue';

  export default {
    name: 'EvergreenQueue',
    data() {
      return {
        isOnboardingEvergreenMessageDismissed: null,
        postsFromCursors: {
          all: [],
          retweets: [],
          likes: [],
          impressions: [],
          replies: [],
          bookmarks: [],
          profileClicks: [],
          hypefuryTweets: [],
        },
        activeTab: 0,
        evergreenTab: 0,
        everActiveTab: 0,
        isSelected: true
      };
    },
    beforeMount() {
      if (!this.evergreenPosts) {
        store.dispatch('fetchEvergreenPosts');
      }
    },
    mounted() {
      this.$eventStore.visitEvergreenPostsPage();
      this.onScroll();
      this.isOnboardingEvergreenMessageDismissed = localStorage.getItem(
        'isOnboardingEvergreenMessageDismissed'
      );
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['evergreenPosts', 'schedule']),
      evergreenPostsRetweetedLastWeek() {
        return this.evergreenPosts.filter((post) => {
          return (
            post.lastAutoRTTime &&
            moment().diff(moment(post.lastAutoRTTime.toDate()), 'seconds') <= 7 * 24 * 60 * 60
          );
        }).length;
      },
      totalEvergreenPosts() {
        return this.evergreenPosts.length;
      },
    },
    components: {
      BackToTopCustom,
      HistoryFilters,
      PollOptions,
      QueueThumbnail,
      HistorySearchField,
      HistoryDateTimeFilter,
      TimeLine,
      TimeLineItem,
      ScheduleRetweetModal,
      ScheduleRetweetRecurrentlyModal,
      TweetsMetricsCardFooter,
      SetBestTweetsAsEvergreenModal,
      horizontalDots,
      darkHorizontalDots
    },
    methods: {
      retweetNow(thread) {
        this.$eventStore.evergreenQueue('Dropdown: Retweet Now');
        this.retweet(thread);
      },
      requeue(thread) {
        this.$eventStore.evergreenQueue('Dropdown: Re-queue');
        this.scheduleThread(thread);
      },
      scheduleRetweetOnce(thread) {
        this.$eventStore.evergreenQueue('Dropdown: Schedule retweet once');
        this.scheduleRetweet(thread);
      },
      scheduleMultipleRetweets(thread) {
        this.$eventStore.evergreenQueue('Dropdown: Schedule multiple retweets');
        this.scheduleRetweetRecurrently(thread);
      },
      getMedia(source, index) {
        if (source.tweets) {
          if (source.tweetReel && source.tweetReel.outputFileUrl) {
            return [{ url: source.tweetReel.outputFileUrl, type: 'reel' }];
          } else if (source.tweets[index].media) {
            return source.tweets[index].media;
          }
          return [];
        }
        return [];
      },
      addToEvergreenQueue: function (thread) {
        thread.toggleFavorite();
        this.updateInspirationTweet(thread.id, { isFavorite: thread.isFavorite });
      },
      createPostsQuery() {
        const queries = dao.posts.getPostsQueriesForEvergreenQueue(
          this.userProfile.uid,
          this.timeRange
        );
        lodash.mapValues(this.searchQueries, (value, key) => {
          queries[key] = value;
        });
        return queries;
      },
      dismissBestTweetsText() {
        this.isOnboardingEvergreenMessageDismissed = true;
        localStorage.setItem('isOnboardingEvergreenMessageDismissed', true);
      },
      async scheduleThread(post) {
        const time = this.schedule.getNextTimeSlot();

        const updatedPostTweets = post.tweets.map((tweet) => ({
          count: tweet.count,
          media: tweet.media,
          published: false,
          status: tweet.status,
          guid: uuidv1(),
        }));

        const thread = Thread.newThread({
          time,
          tweets: updatedPostTweets,
          user: this.userProfile.uid,
        });

        await thread.saveToFirestore(
          this.currentUser,
          this.userProfile.uid,
          this.userProfile.timezone,
          this.$eventStore
        );
        this.$eventStore.scheduleThreadFromEvergreenPage();
        this.$notify({ type: 'success', message: 'Post successfully re-queued!' });
      },
      scrollToHighlightedThreadId: function () {
        const highlightedThreadId = this.$route.params.threadId;
        if (!highlightedThreadId) return;

        this.$nextTick(() => {
          const element = this.$refs[highlightedThreadId];
          if (!element || !element[0]) return;
          scrollToElement(element[0]);
        });
      },
      enableAutoRT() {
        dao.userProfile
          .enableAutoRT(this.userProfile.uid)
          .then(
            this.$notify({
              type: 'success',
              message: 'Empty slots act as Evergreen slots has been successfully enabled.',
            })
          )
          .catch(() => {
            this.swalModal({
              title: 'Sorry',
              text: `An error has occurred while toggling the Empty slots act as Evergreen slots feature.`,
              type: 'error',
            });
          });
      },
    },
    mixins: [
      DeletePostMixin,
      HistoryMixin,
      TrialPeriodMixin,
      SetBestTweetsAsEvergreenMixin,
      SwalModalMixin,
      InspirationTweetsMixin,
      CustomerStatusMixin,
    ],
  };
</script>
