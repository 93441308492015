<script>
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';

  export default {
    data() {
      return {
        isSetBestTweetsAsEvergreenModalVisible: false,
      }
    },
    methods: {
      openSetBestTweetsAsEvergreenModal() {
        this.isSetBestTweetsAsEvergreenModalVisible = true;
      },
      closeSetBestTweetsAsEvergreenModal: function () {
        this.isSetBestTweetsAsEvergreenModalVisible = false;
      },
    },
    mixins:[SwalModalMixin]
  };
</script>
